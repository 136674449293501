import { Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest } from '../redux/actionsAPI';
import {LABEL_EVENT} from '../econf/cnf'

import moment from "moment"

import {dynamicSortMultiple, oValues, firstSortedValue} from "./_tools"

class EventEditModal extends Component {
    regFields = [['stripeProducts', 'List of product IDs for paid registration'], ['paymentURL', "Payment redirect link"], ['registrationCodes', 'Registration codes (comma separated)'], ['registrationManagerMail', 'Registration contact e-mail'], ['registrationManagerName', 'Registration contact name'], ['askCaption', 'Custom caption for request option'], ['specialOptionCaption', 'Extra registration option text'], ['specialOptionInputCaption', 'Extra registration option input caption'], ['specialOptionManagerMail', 'Extra registration option manager e-mail'], ['registrationTemplateThanks', 'Sendgrid template id for "thank you" e-mail'], ['registrationTemplateReceived', 'Sendgrid template id for "request received" e-mail'], ['registrationTemplateDenied', 'Sendgrid template id for "request denied" e-mail'], ['registrationTemplateConfirmed', 'Sendgrid template id for "request confirmed" e-mail'], ['registrationTemplatePaid', 'Sendgrid template id for "payment succeeded" e-mail'], ['registrationTemplateInvoice', 'Sendgrid template id for the invoice e-mail'], ['registrationTemplateOneWeekReminder', 'Sendgrid template id for "One week reminder" e-mail'], ['registrationTemplateOneDayReminder', 'Sendgrid template id for "One day reminder" e-mail'], ['registrationTemplateTodayReminder', 'Sendgrid template id for "Today reminder" e-mail'], ['paidOptionCaption', 'Paid option button caption'], ['paidOptionSub', 'Text below Paid option button'], ['freeOptionCaption', 'Free option button caption'], ['freeOptionSub', 'Text below free option button'], ['streamCode', 'Live stream embedding code'], ['liveSessionHeader', 'Live stream section name']]

    state = {
        name: "",
        dateStart: null,
        dateStartT: null,
        dateEnd: null,
        dateEndT: null,
        registrationEnabled: false, 
        keepOnlyPayment: false,
        registrationCodes: "",
        registrationManagerMail: "",
        registrationManagerName: "",
        registrationTemplate: "",
        registrationTemplateThanks: "", 
        registrationTemplateReceived: "", 
        registrationTemplateDenied: "", 
        registrationTemplateConfirmed: "",
        specialOptionCaption: "",
        specialOptionManagerMail: "",
        codeAnalytic: "",
        isLive: false,
        isCurrent: false,
        paymentURL: ""

    }

    eventsList = []

    componentDidMount() {
        this.setState ({event: firstSortedValue (this.props.productConfig.eventsDic, '*name').id})
        if (this.props.interface.editingEventEditionId in this.props.productConfig.editionsDic)
        {
            var el = this.props.productConfig.editionsDic[this.props.interface.editingEventEditionId]
            const ds = el.dateStart ? moment (el.dateStart, 'DD-MM-YYYY').toDate() : null
            const de = el.dateEnd ? moment (el.dateEnd, 'DD-MM-YYYY').toDate() : null
            var cs = {name: el.subname, isCurrent:el.isCurrent, event: el.idEvent, codeAnalytic:el.codeAnalytic, dateStart:ds, dateStartT:ds ? ds.getTime():ds, dateEnd: de, dateEndT:de ? de.getTime():de, registrationEnabled: el.registrationConfig.registrationEnabled, keepOnlyPayment:el.registrationConfig.keepOnlyPayment, isLive:el.isLive, chatEnabled:el.registrationConfig.chatEnabled}
            for (var f of this.regFields)
                cs[f[0]] = el.registrationConfig[f[0]]
            this.setState (cs)
        }
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };


    render () {
        var that = this
        this.eventsList = oValues (this.props.productConfig.eventsDic).sort (dynamicSortMultiple('*name'))
        return (
            <Modal show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventEditionId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{(that.props.interface.editingEventEditionId > 0) ? "Edit " + LABEL_EVENT + " edition":"Create " + LABEL_EVENT + " edition"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="post" onSubmit={(e)=>{e.preventDefault}}>
                        <div className="mb-3">
                        <div className="control">
                                <label htmlFor="event" className="form-label">{LABEL_EVENT}</label>
                                <select 
                                    name="event"
                                    id="event"
                                    className="form-control"
                                    required
                                    onChange={this.handleInputChange}
                                    value={this.state.event}
                                >
                                    {this.eventsList.map (function (ev) {
                                        return <option selected={ev.id === that.state.event} value={ev.id} key={"selEvent" + ev.id}>{ev.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="control">
                                <label htmlFor="name" className="form-label">Edition name (added to the event name)</label>
                                <input
                                    id="name"
                                    className="form-control"
                                    name="name"
                                    required
                                    onChange={this.handleInputChange}
                                    value={this.state.name}
                                    type="text"
                                />
                            </div>
                            <div className="control mb-3 mt-3">
                                <input
                                    className="form-check-input"
                                    name="isCurrent"
                                    onChange={that.handleCBChange}
                                    checked={that.state["isCurrent"]}
                                    type="checkbox"
                                />
                                <label htmlFor="isCurrent" className="form-check-label ms-2">Is Current</label>
                                {that.props.profile.isMarketing || that.props.profile.isAdmin ? <a className='ms-3' href={"/edition/live?editionID=" + this.props.interface.editingEventEditionId} target='_blank'>Open live monitor</a>:null}
                            </div>
                            
                            { that.props.profile.isExAdmin ? 
                            <div className="control">
                                <label htmlFor="name" className="form-label">Code analytic</label>
                                <input
                                    id="codeAnalytic"
                                    className="form-control"
                                    name="codeAnalytic"
                                    required
                                    onChange={this.handleInputChange}
                                    value={this.state.codeAnalytic}
                                    type="text"
                                />
                                <Button variant="secondary" className='mt-3 mb-3' onClick={(e)=>{ this.props.dispatch (apiRequest({'endpoint': 'createCodeAnalytique', "eventEditionId": that.props.interface.editingEventEditionId}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventEditionId", "paramValue": null})) }}>
                                    Check and create the code analytic
                                </Button>
                                 
                            </div>:null}
                            <div className="control">
                                <label htmlFor="name" className="form-label">Start date</label>
                                <DatePicker 
                                    selected={that.state.dateStart} 
                                    dateFormat="dd-MM-yyyy"
                                    calendarStartDay={1}
                                    onChange={(date) => { that.setState ({dateStart:date, dateStartT: date.getTime()}); }}/>
                            </div>
                            <div className="control">
                                <label htmlFor="name" className="form-label">End date</label>
                                <DatePicker 
                                    selected={that.state.dateEnd} 
                                    dateFormat="dd-MM-yyyy"
                                    calendarStartDay={1}
                                    onChange={(date) => { that.setState ({dateEnd:date, dateEndT: date.getTime()});}}/>
                            </div>
                            <div className='registrationRelated mt-3'>
                                <h4>Registration</h4>
                                <div className="control mb-3 mt-3">
                                    <input
                                        className="form-check-input"
                                        name="registrationEnabled"
                                        onChange={that.handleCBChange}
                                        checked={that.state["registrationEnabled"]}
                                        type="checkbox"
                                    />
                                    <label htmlFor="registrationEnabled" className="form-check-label ms-2">Registration is enabled</label>
                                    {(navigator.clipboard && window.isSecureContext) ?<Button variant='secondary' className='ms-3' onClick={(e)=>{ 
                                        e.preventDefault ()
                                        var code = 'https://crm.ebsummit.eu/event/register?eventID=' + this.props.interface.editingEventEditionId + '&regCode='
                                        navigator.clipboard.writeText(code);
                                        }}><i className={"fs-4 bi-code-square"}></i></Button>:null}
                                </div>
                                <div className="control mb-3 mt-3">
                                    <input
                                        className="form-check-input"
                                        name="keepOnlyPayment"
                                        onChange={that.handleCBChange}
                                        checked={that.state["keepOnlyPayment"]}
                                        type="checkbox"
                                    />
                                    <label htmlFor="keepOnlyPayment" className="form-check-label ms-2">Keep only paid option (no form)</label>
                                </div>


                                {that.regFields.map (function (el) {
                                    return <div key={"regEl" + el[0]} className="control">
                                        <label htmlFor={el[0]} className="form-label">{el[1]}</label>
                                        <input
                                            className="form-control"
                                            name={el[0]}
                                            onChange={that.handleInputChange}
                                            value={that.state[el[0]]}
                                            type="text"
                                        />
                                    </div>
                                
                                })}
                                <div className="control mb-3 mt-3">
                                    <input
                                        className="form-check-input"
                                        name="isLive"
                                        onChange={that.handleCBChange}
                                        checked={that.state["isLive"]}
                                        type="checkbox"
                                    />
                                    <label htmlFor="isLive" className="form-check-label ms-2">Is Live</label>
                                    {that.props.profile.isMarketing || that.props.profile.isAdmin ? <a className='ms-3' href={"/edition/live?editionID=" + this.props.interface.editingEventEditionId} target='_blank'>Open live monitor</a>:null}
                                </div>
                                <div className="control mb-3 mt-3">
                                    <input
                                        className="form-check-input"
                                        name="chatEnabled"
                                        onChange={that.handleCBChange}
                                        checked={that.state["chatEnabled"]}
                                        type="checkbox"
                                    />
                                    <label htmlFor="chatEnabled" className="form-check-label ms-2">Chat Enabled</label>
                                </div>
                            </div>
                        </div>    
                    </form>    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventEditionId", "paramValue": null})}}>
                    Close
                </Button>
                <Button variant="primary" onClick={(e)=>{ this.props.dispatch (apiRequest({'endpoint': 'editEventEdition', "eventEditionId": that.props.interface.editingEventEditionId, "newState":that.state}, {type:MODIFY_INTERFACE_PARAM, "paramName": "editingEventEditionId", "paramValue": null})) }}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        productConfig: state.data.productConfig,
        profile: state.data.profile
    }
}

export default connect(mapStateToProps)(EventEditModal)